import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { fnModalAlert } from '@/components/common/modal/alert'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    searchWords: computed(() => {
      if (proxy.$Util.isEmpty(state.market.searchWord)) return []
      return state.market.searchWord.split(',')
    }),
    rewardPoint: 0,
    cardFee: 0,
    cashFee: 0,
    form: {},
    params: route.query,
    thumbnail: [],
    market: {},
    leader: {},
    pinList: [],
    computedPinList: computed(() =>
      state.pinList.map(el => {
        let text
        if (el.useDt) {
          text = `사용기한 ${proxy.$dayjs(el.useDt).format('YYYY-MM-DD')} 까지`
        } else {
          text = '기한 없음'
        }
        return { ...el, text }
      })
    ),
    checkList: [],
    viewResOptions: computed(() => {
      if (proxy.$Util.isEmpty(state.market)) {
        return false
      } else {
        if (
          [
            state.market.resKakao,
            state.market.resPhone,
            state.market.resEmail
          ].some(r => r)
        ) {
          return true
        } else {
          return false
        }
      }
    })
  })

  const fnEdit = async () => {
    await router.replace({
      path: '/my-page/market/edit',
      query: {
        marketNo: state.market.marketNo
      }
    })
  }

  const fnReqOptionEdit = async () => {
    let data = {
      resKakao: state.market.resKakao,
      resPhone: state.market.resPhone,
      resEmail: state.market.resEmail
    }
    const resOptions = Object.keys(data).reduce(
      (pv, cv) => (data[cv] ? { ...pv, [cv]: true } : { ...pv }),
      {}
    )

    data.resOptions = resOptions

    const payload = {
      component: proxy.$modalAlertNames.REQ_OPTION_EDIT,
      data,
      callback: async resOptions => {
        await fnSaveReqOption(resOptions)
      }
    }
    await fnModalAlert(payload)
  }

  const fnSaveReqOption = async resOptions => {
    const params = {
      marketNo: state.params.marketNo,
      memberSeq: state.params.memberSeq,
      ...resOptions
    }
    const res = await proxy.$MarketSvc.postMyPageBuyMarketUpdateResOption(
      params
    )
    if (res.resultCode === '0000') {
      state.market = {
        ...state.market,
        ...resOptions
      }
    } else {
      alert(res.resultMsg)
    }
  }

  const fnDetail = async () => {
    const res = await proxy.$MarketSvc.postMyPageBuyMarketDetail(state.params)
    if (res.resultCode === '0000') {
      state.market = res.market
      state.pinList = res.pinList
      state.thumbnail = res.thumbnail
      state.leader = res.leader
    } else {
      alert(res.resultMsg)
    }
  }

  const fnDelete = async () => {
    if (
      !confirm(
        '구매한 상품을 삭제 하시겠습니까?\n삭제 시 해당 구매내역 전체가 삭제됩니다.'
      )
    )
      return
    const res = await proxy.$MarketSvc.postMyPageBuyMarketDelete(state.params)
    if (res.resultCode === '0000') {
      alert('삭제되었습니다.')
      router.go(-1)
    } else {
      alert(res.resultMsg)
    }
  }

  // TODO: 분쟁 취소?
  const fnCancelArgument = async () => {
    const params = {
      seq: state.runningConflict.seq
    }
    const res = await proxy.$MarketSvc.cancelRefundDelete(params)
    if (res.resultCode === '0000') {
      alert('취소 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnMarketPaymentApproval = async () => {
    if (proxy.$Util.isEmpty(state.checkList)) {
      alert('구매 확정 하실 상품을 선택해주세요.')
      return
    }
    if (
      !confirm(
        `선택 된 상품 (${state.checkList.length}개)를\n구매 확정 하시겠습니까?`
      )
    )
      return

    const params = { ...state.params }
    delete params.tabIndex
    params.pinSeqList = state.checkList.reduce((pv, cv) => [...pv, cv.seq], [])
    const res = await proxy.$CashSvc.postMarketPaymentApproval(params)
    if (res.resultCode === '0000') {
      alert('구매 확정이 완료 되었습니다.')
      await fnDetail()
    } else {
      alert(res.resultMsg)
    }
  }

  const fnWizztalk = async () => {
    const params = {}
    params.marketNo = route.query.marketNo
    params.memberSeq = route.query.memberSeq
    const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
    if (res.resultCode === '0000') {
      params.chatroomId = res.chatroomId
      await router.push({
        path: '/market/wizz-talk',
        query: params
      })
    } else {
      alert(res.resultMsg)
    }
  }

  const getStatusStyle = (status, hasBadge) => {
    let style = {}
    if (status == 2 || status == 4 || status == 5) {
      style.done = true
    } else if (status == 1) {
      style.waiting = true
    } else if (status == 0) {
      style.normal = true
    } else {
      style.conflict = true
    }

    if (hasBadge == '1') style.new = true

    return style
  }

  const fnModalAlertSellerProfile = () => {
    const payload = {
      component: proxy.$modalAlertNames.SELLER_PROFILE,
      data: {
        userNm: state.leader.userNm,
        userLvNm: state.leader.userLvNm,
        sellerLv: state.leader.sellerLv,
        profile: state.leader.profile,
        productNo: route.query.marketNo,
        productType: 'MARKET',
        userData: state.userData,
        memberSeq: route.query.memberSeq
      },
      callback: () => {}
    }
    fnModalAlert(payload)
  }

  const fnBuyerViewDetail = pinSeq => {
    let query = { ...state.params, pinSeq }
    router.push({
      name: 'my-page-market-buyer-view-detail-index',
      query
    })
  }

  const init = async () => {
    await fnDetail()
  }

  init()

  return {
    ...toRefs(state),
    fnEdit,
    fnDetail,
    fnDelete,
    fnCancelArgument,
    fnWizztalk,
    fnMarketPaymentApproval,
    getStatusStyle,
    fnBuyerViewDetail,
    fnReqOptionEdit,
    fnModalAlertSellerProfile
  }
}
