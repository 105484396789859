<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="detail_top">
            <div class="market_thumb" v-if="!$Util.isEmpty(thumbnail)">
              <swiper
                :slides-per-view="1"
                :centered-slides="true"
                :speed="500"
                :autoplay="{ delay: 3000 }"
              >
                <swiper-slide v-for="(item, index) in thumbnail" :key="index">
                  <div class="img_area">
                    <img :src="item" alt="" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <div class="market_thumb" v-else>
              <div class="img_area">
                <img src="@/assets/static/images/no_image.png" alt="" />
              </div>
            </div>
            <div class="party_ttl">{{ market.title }}</div>
            <div class="party_keyword" v-if="!$Util.isEmpty(searchWords)">
              <span v-for="(item, index) in searchWords" :key="index">{{
                `#${item}`
              }}</span>
            </div>
          </div>
          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>구매 내역</dt>
                <dd>
                  <div class="form" style="margin-top: 1rem;">
                    <ul class="form_ul">
                      <li
                        class="form_li"
                        v-for="(item, index) in computedPinList"
                        :key="index"
                      >
                        <div class="inline">
                          <label
                            style="cursor: pointer;"
                            @click="fnBuyerViewDetail(item.seq)"
                            class="input"
                          >
                            <input
                              type="text"
                              class="input_btn"
                              :class="{
                                on: !(item.status == '2' || item.status == '5')
                              }"
                              style="cursor: pointer;"
                              :value="item.text"
                              disabled
                            />
                          </label>
                          <div
                            class="status"
                            :class="getStatusStyle(item.status, item.hasBadge)"
                          >
                            {{ item.statusNm }}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div v-if="viewResOptions" class="lump bo_zero">
            <div class="form">
              <div class="form_ttl" style="color: #999; font-weight: 500;">
                구매자 요청정보
              </div>
              <ul class="form_ul">
                <li v-if="market.resKakao" class="form_li">
                  <div class="form_ttl essen">카카오톡 ID</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="카카오톡 ID를 입력해주세요"
                        v-model="market.resKakao"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="market.resPhone" class="form_li">
                  <div class="form_ttl essen">휴대폰번호</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="휴대폰번호를 입력해 주세요"
                        v-model="market.resPhone"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
                <li v-if="market.resEmail" class="form_li">
                  <div class="form_ttl essen">이메일</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="text"
                        placeholder="이메일을 입력해주세요"
                        v-model="market.resEmail"
                        disabled
                        :maxlength="100"
                      />
                    </label>
                  </div>
                </li>
              </ul>
              <div v-if="market.isDel != '1'" class="btn_wrap">
                <button class="btn mt10" @click="fnReqOptionEdit">
                  구매자 요청정보 수정
                </button>
              </div>
            </div>
          </div>
          <div class="lump">
            <div class="table_type01">
              <dl>
                <dt>구매 수량</dt>
                <dd>{{ pinList.length }}개</dd>
              </dl>
              <dl>
                <dt>총 결제금액</dt>
                <dd>
                  {{ $Util.formatNumber2(market.price, { unit: '원' }) }}
                </dd>
              </dl>
            </div>
            <div class="table_type02">
              <dl>
                <dt>결제 방법</dt>
                <dd>{{ market.paymentType }}</dd>
              </dl>
              <dl>
                <dt>결제일</dt>
                <dd>{{ market.payRegDt }}</dd>
              </dl>
            </div>
          </div>
          <div class="lump">
            <div class="table_type02">
              <dl>
                <dt>판매자</dt>
                <dd>
                  <div class="flex">
                    <div
                      class="prof"
                      :class="[
                        {
                          off:
                            leader.userLvNm === $ConstCode.LEVEL_TYPE['0'].name
                        },
                        {
                          vip:
                            leader.sellerLv >= 3
                        },
                        {
                          nonebg:
                            !$Util.isEmpty(leader.profile) &&
                            leader.profile.indexOf('/null') < 0
                        }
                      ]"
                    >
                      <!-- 프로필이미지 지정시 nonebg 추가 -->
                      <div class="img_area">
                        <img
                          :src="leader.profile"
                          alt=""
                          v-if="
                            !$Util.isEmpty(leader.profile) &&
                              leader.profile.indexOf('/null') < 0
                          "
                        />
                      </div>
                    </div>
                    <div class="nickname">
                      {{ leader.userNm }}
                    </div>
                    <div @click="fnModalAlertSellerProfile" class="right">
                      판매자 정보 보기
                    </div>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="lump bo_zero">
            <div class="table_type02">
              <dl>
                <dt>
                  상품 소개 글 및 주의사항<span class="table_date"
                    >{{ $dayjs(market.contentsRegDt).format('YYYY-MM-DD')
                    }}<span class="error" v-if="market.hasContentsBadge === '1'"
                      >N</span
                    ></span
                  >
                </dt>
                <dd class="editor">
                  <div>
                    {{ market.contents }}
                  </div>
                </dd>
              </dl>
            </div>
          </div>
          <div class="con_btn" style="margin-top: 0;">
            <!--210713 style-->
            <div class="btn_wrap">
              <button class="btn bo" @click="fnWizztalk">
                위즈톡<span class="wiz_icon"></span>
              </button>
              <button
                class="btn bo"
                v-if="market.isDel == '1'"
                @click="fnDelete"
              >
                상품 삭제
              </button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>주의사항</dt>
                <dd>
                  <div>
                    * 결제일로부터 3일이 지나면 자동으로 구매 확정 처리가
                    됩니다.
                  </div>
                  <div>
                    * 구매확정 진행 후 환불 요청이 불가능합니다.
                  </div>
                  <div>
                    * 환불 요청시 판매자 의사에 따라 반려 처리될 수 있습니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'my-page-market-view-index',
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
